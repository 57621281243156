body {
    height:100%;
    margin:0;
}

.App {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; /* Prevent scrollbars */
}

.AppContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr); /* 3 equal rows */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
}

.cell {
    background-color: lightgray;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: 100%; /* Image width will always be 100% of the container */
    height: auto; /* Height will adjust automatically to maintain aspect ratio */
    min-height: 1px;
    min-width: 1px;
    object-fit: fill;
}